import { Routes } from '@angular/router';
import { authRoutes, canActivateAuth } from '@origin8-web/core-ui/login/routes';

import {
  ADD_NEW_TAG_ROUTE,
  CALL_TRANSCRIPT_ROUTE,
  CALLS_TO_REVIEW_ROUTE,
  ArcPagesPermissions,
  EDIT_TAG_ROUTE,
  MANAGE_TAGS_ROUTE,
  TRANSCRIPT_GRADE_SUMMARY_ROUTE,
} from '../app.interface';
import { canActivatePermissions } from '@origin8-web/core-ui/login/providers';

export const routes: Routes = [
  ...authRoutes,
  {
    path: CALL_TRANSCRIPT_ROUTE.BASE,
    canActivate: [canActivateAuth],
    loadComponent: () =>
      import('../features/call-transcript/components/call-transcript.component').then((c) => c.CallTranscriptComponent),
  },
  {
    path: TRANSCRIPT_GRADE_SUMMARY_ROUTE.BASE,
    canActivate: [canActivateAuth],
    loadComponent: () =>
      import('../features/transcript-grade-summary/transcript-grade-summary.component').then(
        (c) => c.TranscriptGradeSummaryComponent,
      ),
  },
  {
    path: 'unauthorized',
    loadComponent: () => import('../features/unauthorized/unauthorized.component').then((m) => m.UnauthorizedComponent),
  },
  {
    path: CALLS_TO_REVIEW_ROUTE.BASE,
    loadComponent: () =>
      import('../features/calls-to-review/calls-to-review.component').then((m) => m.CallsToReviewComponent),
    canActivate: [canActivateAuth, canActivatePermissions(ArcPagesPermissions.callsToReviewPerms)],
  },
  {
    path: MANAGE_TAGS_ROUTE.BASE,
    loadComponent: () => import('../features/manage-tags/manage-tags.component').then((m) => m.ManageTagsComponent),
    canActivate: [canActivateAuth, canActivatePermissions(ArcPagesPermissions.manageTagsPerms)],
  },
  {
    path: ADD_NEW_TAG_ROUTE.BASE,
    loadComponent: () =>
      import('../features/manage-tags/add-new-tag/add-new-tag.component').then((m) => m.AddNewTagComponent),
    canActivate: [canActivateAuth, canActivatePermissions(ArcPagesPermissions.manageTagsPerms)],
  },
  {
    path: EDIT_TAG_ROUTE.BASE,
    loadComponent: () => import('../features/manage-tags/edit-tag/edit-tag.component').then((m) => m.EditTagComponent),
    canActivate: [canActivateAuth, canActivatePermissions(ArcPagesPermissions.manageTagsPerms)],
  },
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('../features/dashboard/dashboard.component').then((mod) => mod.DashboardComponent),
    canActivate: [canActivateAuth, canActivatePermissions(ArcPagesPermissions.dashboardPermissions)],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
