<div class="w-4/12 block fixed top-0 right-0 mt-20 mx-2.5 mb-0 bg-gray-50 border rounded-md border-green-200 shadow-md">
  <div
    class="menu-item text-base leading-4 text-black-500 font-bold py-7 px-6 border-b border-green-200 hover:bg-gray-100"
    [routerLink]="links.dashboard"
    routerLinkActive="active-navlink"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Dashboard
  </div>
  <div
    class="menu-item text-base leading-4 text-black-500 font-bold py-7 px-6 border-b border-green-200 hover:bg-gray-100"
    [routerLink]="links.callsToReview"
    routerLinkActive="active-navlink"
    *o8OnlyPermissions="permissions.callsToReviewPerms"
  >
    Calls to review
  </div>
  <!-- REMOVE WHEN TICKET COMPLETE <div
    class="menu-item text-base leading-4 text-black-500 font-bold py-7 px-6 border-b border-green-200 hover:bg-gray-100"
    [routerLink]="links.manageTags"
    routerLinkActive="active-navlink"
    *o8OnlyPermissions="permissions.manageTagsPerms"
  >
    Manage tags
  </div> -->
  <div
    class="menu-item text-base leading-4 text-black-500 font-bold py-7 px-6 border-b border-green-200 hover:bg-gray-100"
    [routerLink]="links.logout"
    routerLinkActive="active-navlink"
  >
    Logout
  </div>
</div>
